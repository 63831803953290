import React, { useState, useEffect } from "react";
import logo from "../../Media/logo4.png";
import logo1 from "../../Media/MLM dashB/Gamecoinclub.png";
import "./Navbar.css";

import { BsFillPersonFill } from "react-icons/bs";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  NavLink,
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { MdLeaderboard } from "react-icons/md";
import { IoMdSwap } from "react-icons/io";
import { FaCreditCard } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import { MdPayments } from "react-icons/md";
import { IoMdTrendingUp } from "react-icons/io";
import { MdOutlineLegendToggle } from "react-icons/md";
import { MdOutlineMemory } from "react-icons/md";
import { FaKey } from "react-icons/fa";
import StakeSettingModal from "./StakeSettingModal";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API } from "../../API/API";
import colorimg from "../../Media/output-onlinepngtools (8).png";
import aircraft from "../../Media/output-onlinepngtools (5).png";
import { NavbarCollapse } from "react-bootstrap";
import { RxFontStyle } from "react-icons/rx";
// import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
function Navbar({ toggleSidebar, showSidebar }) {
  // const { userBalance } = useSelector((state) => state.userBalanceData);
  const uid = sessionStorage.getItem("userData");
  const uName = sessionStorage.getItem("userName");
  // useEffect(() => {
  //   dispatch(fetchuserBalance({ id: uid, token }));
  // }, []);
  const location = useLocation();
  const navigate = useNavigate();

  function stop() {
    document.getElementById("marquee1").stop();
  }

  function start() {
    document.getElementById("marquee1").start();
  }

  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);

  const [active, setActive] = useState(window.location.pathname);
  const SOCKET_URL = "https://gamecoin-socket.nakshtech.info/";

  const displayModal = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    sessionStorage.clear();
    dispatch(logoutUser());
  };
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    // Update active link on route change

    const path = location.pathname;

    if (path === "/") {
      setActive("/");
    } else if (path === "/InPlay") {
      setActive("/InPlay");
    } else if (path === "/LiveCasino") {
      setActive("/LiveCasino");
    }
  }, [location.pathname]);

  const [userBalance, setBalance] = useState({});
  useEffect(() => {
    if (uid) {
      const cricketMatchesSocket = io(SOCKET_URL);
      cricketMatchesSocket.emit("GetWalletExposureSocket", uid);
      cricketMatchesSocket.on("GetWalletExposureSocket_FromAPI", (data) => {
        // console.log("userbalance",data[0][0])
        setBalance(data[0][0]);
      });

      return () => {
        cricketMatchesSocket.disconnect();
      };
    }
  }, [uid]);
  const [news, setNews] = useState([]);
  async function News_API() {
    try {
      let res = await API.post(
        `/userNews`,
        {
          id: 0,
          content: "",
          var: 5,
          username: uid,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("news_APi", res);
      if (res?.data?.data?.record[0]?.length > 0) {
        setNews(res?.data?.data?.record[0]);
      }
    } catch (error) {
      console.log("Error News", error);
      if (
        error.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  }

  // console.log("news", news);
  useEffect(() => {
    if (uid) {
      News_API();
    } else {
      setNews([]);
    }
  }, [uid]);
  return (
    <nav className="navbar py-0  navbar-b d-block navbar-expand-lg navbar-dark nav-bg">
      <div className="">
        <div className="row align-items-center">
          <div className=" col-7 col-lg-3 col-sm-6  order-lg-1 order-2 ">
            <a className="navbar-brand" href="">
              <img className="navbar-brand ms-3" src={logo1} alt="logo" />
            </a>
          </div>
          <div className="col-lg-6 order-lg-2 d-lg-block  d-none ">
            <div className="d-flex justify-content-center pt-2 ">
              {/* <div className="single-sport-div1 ms-1">
              <NavLink to="/Aviator" className="nav-link">
                <div className="blink-img-div1">
                 
                    <img
                      className="image-size1 img-fluid"
                      src={aircraft}
                      alt="a"
                    />
                  
                </div>
                <p className="ms-1 sport-name">AVIATOR</p>
              </NavLink>
            </div> */}

              <NavLink
                onClick={() => {
                  setActive("/");
                }}
                to="/"
                className={` nav-btn ${active === "/" && "is-active"}`}
              >
                SPORTS
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/InPlay");
                }}
                className={` nav-btn ${active === "/InPlay" && "is-active"}`}
                to="/InPlay"
              >
                INPLAY
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/LiveCasino");
                }}
                to="/LiveCasino"
                className={` nav-btn ${
                  active === "/LiveCasino" && "is-active"
                }`}
              >
                LIVE&nbsp;TABLE{" "}
              </NavLink>

              <NavLink
                onClick={() => {
                  setActive("/DragonTiger/5");
                }}
                to="/DragonTiger/5"
                className={` nav-btn ${
                  active === "/DragonTiger/5" && "is-active"
                }`}
              >
                DRAGON&nbsp;TIGER{" "}
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/ColorGame");
                }}
                to="/ColorGame"
                className={` nav-btn ${active === "/ColorGame" && "is-active"}`}
              >
                COLOR{" "}
              </NavLink>
              <NavLink
                onClick={() => {
                  setActive("/Aviator");
                }}
                to="/Aviator"
                className={` nav-btn ${active === "/Aviator" && "is-active"}`}
              >
                AVIATOR{" "}
              </NavLink>
              {/* <NavLink
                onClick={() => {
                  setActive("/AccountStatement");
                }}
                to="/AccountStatement"
                className={` nav-btn ${
                  active === "/AccountStatement" && "is-active"
                }`}
              >
                Statement{" "}
              </NavLink> */}
              {/* <NavLink
                onClick={() => {
                  setActive("/CasinoResults");
                }}
                to="/CasinoResults"
                className={` nav-btn ${
                  active === "/CasinoResults" && "is-active"
                }`}
              >
                Result{" "}
              </NavLink> */}
            </div>
          </div>
          <div className="col-3 col-lg-3  col-md-3 col-sm-3 order-lg-3 order-3  order-xsm-3 d-flex justify-content-end  ">
            {token ? (
              <>
                <span
                  className="white-space-nowrap mt-2"
                  style={{ fontSize: "x-small" }}
                >
                  <span style={{ color: "white" }}>PTS: </span>
                  {parseFloat(userBalance?.wallet_amount || 0).toFixed(2)}
                  <br />
                  <span style={{ color: "white" }}>EXP: </span>
                  {userBalance?.exp_amount &&
                    userBalance?.exp_amount > 0 &&
                    "-"}{" "}
                  {parseFloat(userBalance?.exp_amount || 0).toFixed(2)}
                  <br />
                  <span style={{ color: "white" }}>User: </span>
                  {uName}
                </span>
                &nbsp;&nbsp;
                <div
                  className="dropdown mt-0"
                  onClick={() => setShow(!show)}
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                >
                  <button className="dropbtn mt-2 pt-0 d-flex">
                    <div className="person-icon-div">
                      <BsFillPersonFill className="person-icon" />
                    </div>
                    <IoMdArrowDropdown className="mt-2" />
                  </button>
                  <div
                    className="dropdown-content"
                    style={{
                      display: show ? "block" : "none",
                      maxHeight: "500px",
                      overflowY: "scroll",
                    }}
                  >
                    {/* <NavLink to="/mlmdashboard" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Dashboard MLM
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink> */}
                    <NavLink to="/profile" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        My Profile
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink>
                    <NavLink to="/BankDetails" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Bank Details
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink>
                    {/* <NavLink to="/GameDeposit">
                      <div className="d-flex justify-content-between">
                        Game Deposit
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink>
                    <NavLink to="/GameDepositHistory">
                      <div className="d-flex justify-content-between">
                        Game Deposit History
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink> */}
                    {/* <NavLink to="/Activation">
                      <div className="d-flex justify-content-between">
                        Activate / Upgrade
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink>
                    <NavLink to="/ActivationHistory">
                      <div className="d-flex justify-content-between">
                        Activate / Upgrade History
                        <FaCreditCard />{" "}
                      </div>
                    </NavLink> */}
                    <NavLink
                      to="/DepositPayment"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Deposit Payment
                        <IoMdTrendingUp />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/DepositPaymentHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Deposit Payment History
                        <IoMdTrendingUp />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/BettingHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Betting History
                        <MdLeaderboard />{" "}
                      </div>
                    </NavLink>
                    <NavLink
                      to="/ColorGameHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Color Bet History
                        <MdLeaderboard />{" "}
                      </div>
                    </NavLink>
                    <NavLink
                      to="/AviatorBetHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Aviator Bet History
                        <MdLeaderboard />{" "}
                      </div>
                    </NavLink>
                    <NavLink to="/ProfitLoss" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Profit-Loss
                        <MdOutlineLegendToggle />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/AccountStatement"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Game Wallet Statement <MdPayments />{" "}
                      </div>
                    </NavLink>
                    {/* <NavLink
                      to="/WithdrawalStatement"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Withdrawal Wallet Statement <MdPayments />{" "}
                      </div>
                    </NavLink> */}
                    {/* <NavLink
                      to="/TransactionHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content- between">
                        Transaction History
                        <IoMdTrendingUp />
                      </div>
                    </NavLink> */}
                    <NavLink to="/MyMarkeet" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between ">
                        Unsettled Bet
                        <MdLeaderboard />
                      </div>
                    </NavLink>

                    {/* <NavLink to="/FundsTransfer" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Fund Transfer
                        <IoMdSwap />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/FundsTransferHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Fund Transfer History
                        <IoMdSwap />
                      </div>
                    </NavLink> */}

                    {/* <NavLink>
                    <div
                      onClick={() => displayModal()}
                      className="d-flex justify-content-between">
                      STAKE SETTING
                      <MdOutlineMemory />
                    </div>
                  </NavLink> */}
                    {modal && <StakeSettingModal closeModal={closeModal} />}
                    <NavLink to="/CasinoResults" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Casino Result
                        <MdLeaderboard />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/ChangePassword"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Change Password
                        <FaKey />
                      </div>
                    </NavLink>
                    <NavLink to="/ButtonValue" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Set Button Values
                        <FaKey />
                      </div>
                    </NavLink>
                    {/* <NavLink
                      to="/WithdrawalFundConvert"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Withdrawal Fund Convert
                        <FaKey />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/FundConvertHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Withdrawal Fund Convert History
                        <FaKey />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/gamewalletreceive"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Game Wallet Receive History
                        <FaKey />
                      </div>
                    </NavLink> */}
                    {/* <NavLink to="/FrenWalletRecive" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                      Franchisee Fund Receive History
                        <FaKey />
                      </div>
                    </NavLink> */}
                    {/* <NavLink to="/FrenFundConvert" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                        Franchisee Fund Convert
                        <FaKey />
                      </div>
                    </NavLink> */}
                    {/* <NavLink to="/FrenFundConvertHistory" onClick={() => setShow(false)}>
                      <div className="d-flex justify-content-between">
                      Franchisee Fund Convert History
                        <FaKey />
                      </div>
                    </NavLink> */}
                    {/* <NavLink
                      to="/WithdrawalUSDT"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        MLM Withdrawal
                        <FaCreditCard />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/WithdrawalHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        MLM Withdrawal History <FaList />{" "}
                      </div>
                    </NavLink> */}
                    <NavLink
                      to="/WithDrawalGame"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Game Withdrawal
                        <FaCreditCard />
                      </div>
                    </NavLink>
                    <NavLink
                      to="/GameWithdrawHistory"
                      onClick={() => setShow(false)}
                    >
                      <div className="d-flex justify-content-between">
                        Game Withdrawal History
                        <FaCreditCard />
                      </div>
                    </NavLink>

                    <NavLink onClick={handleLogout} to="/">
                      <div className="d-flex justify-content-between">
                        LOGOUT
                        <FaKey />
                      </div>
                    </NavLink>
                  </div>
                </div>
              </>
            ) : (
              <div className="">
                <NavLink to="/Login">
                  <button className="signin-btn mt-2  me-3">SignIn</button>
                </NavLink>
                <NavLink to="/registration">
                  <button className="wallet-btn mt-2  me-3">
                    Registration
                  </button>
                </NavLink>
                {/* <NavLink to="/WalletSignIn">
                  <button className="wallet-btn mt-2  me-3">
                    Wallet Sign...
                  </button>
                </NavLink> */}
              </div>
            )}
          </div>

          <div className=" col-2 col-lg-3 col-sm-3  order-lg-4 order-1 ">
            {/* Toggler/collapsible Button */}
            <button
              className="navbar-toggler mt-2 border-none shadow-none"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            {/* Navbar links */}
            <div
              className={`collapse navbar-collapse${
                showSidebar ? " show" : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  {/* <a className="nav-link" href="#">Link</a> */}
                </li>
                {/* Add more navbar links here if needed */}
              </ul>
            </div>
          </div>
        </div>
        {news && news.length > 0 && (
          <div className="row mb-2">
            <marquee
              id="marquee1"
              direction="left"
              scrollamount="5"
              onMouseOver={stop}
              onMouseOut={start}
              className="text-white"
            >
              <ol style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                {news.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      display: "inline",
                      marginRight: "10px",
                      fontSize: "12px",
                      fontWeight: "400",
                      fontStyle: "italic",
                    }}
                  >
                    {item?.content} &nbsp;
                  </li>
                ))}
              </ol>
            </marquee>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
